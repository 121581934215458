const Markets = [
    {
        stocks: [{
            'TPLP': {
                '5Y': 237,
                '4Y': 295,
                '3Y': 302,
                '2Y': 543,
                '1Y': 250,
            },
            'SGF': {
                '5Y': 0,
                '4Y': 0,
                '3Y': 0,
                '2Y': 0,
                '1Y': -18,
            },
            'MODAM': {
                '5Y': 185,
                '4Y': 168,
                '3Y': 159,
                '2Y': 241,
                '1Y': 165,
            },
            'IBFL': {
                '5Y': 145,
                '4Y': 161,
                '3Y': 183,
                '2Y': 183,
                '1Y': 17,
            },
            'TGL': {
                '5Y': 100,
                '4Y': 113,
                '3Y': 154,
                '2Y': 142,
                '1Y': 63,
            },
            'EPCL': {
                '5Y': 229,
                '4Y': 148,
                '3Y': 175,
                '2Y': 228,
                '1Y': 66,
            },
            'EFERT': {
                '5Y': 62,
                '4Y': 31,
                '3Y': 35,
                '2Y': 61,
                '1Y': 41,
            },

            'GVGL': {
                '5Y': 411,
                '4Y': 239,
                '3Y': 194,
                '2Y': 84,
                '1Y': 33,
            },
            'MEBL': {
                '5Y': 174,
                '4Y': 171,
                '3Y': 100,
                '2Y': 184,
                '1Y': 46,
            },
            'AVN': {
                '5Y': 337,
                '4Y': 236,
                '3Y': 161,
                '2Y': 321,
                '1Y': 37,
            },
            'SYS': {
                '5Y': 933,
                '4Y': 875,
                '3Y': 788,
                '2Y': 520,
                '1Y': 75,
            },
            'TELE': {
                '5Y': 225,
                '4Y': 599,
                '3Y': 1023,
                '2Y': 1146,
                '1Y': -16,
            },
        }],
        mutualFunds: {

        },
        forex: {

        },
        oil: {

        },
        gold: {

        },
        deposit: {

        }
    }]

export default Markets
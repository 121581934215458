import React from 'react'
import { NavLink } from 'react-router-dom';
import Logo from '../../../Assets/Images/Logo.png'
import facebook from '../../../Assets/Images/facebook.svg'
import insta from '../../../Assets/Images/insta.svg'
import linkedin from '../../../Assets/Images/linkedin.svg'
import twitter from '../../../Assets/Images/twitter.svg'
import JamaPunji from '../../../Assets/Images/JamaPunji.svg'
import SECP from '../../../Assets/Images/SECP.svg'

const Footer = () => {
    return (
        <>
            <div className="container mt-60 pb-5">
                <div className="row">
                    <div className="col-sm-6 col-md-4 order-1">
                        <NavLink className="navbar-brand" to="/">
                            <img src={Logo} alt="" className="logo" />
                        </NavLink>
                    </div>
                    <div className="col-sm-6 col-md-4 mx-auto order-3 order-md-2">
                        <div className="navbar-nav justify-content-between flex-row">
                            <NavLink className="nav-link fw-700 t-plum" aria-current="page" to="/">Home</NavLink>
                            <NavLink className="nav-link fw-700 t-plum" to="AboutUs">About Us</NavLink>
                            <NavLink className="nav-link fw-700 t-plum" to="Careers">Careers</NavLink>
                            <NavLink className="nav-link fw-700 t-plum" to="FAQs">FAQs</NavLink>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 mx-auto order-2 order-md-3">
                        <div className="navbar-nav justify-content-end flex-row">
                            <NavLink className="nav-link fw-700 t-plum ms-5" aria-current="page" to="/"><img src={facebook} /></NavLink>
                            <NavLink className="nav-link fw-700 t-plum ms-5" to="FAQs"><img src={twitter} /></NavLink>
                            <NavLink className="nav-link fw-700 t-plum ms-5" to="AboutUs"><img src={insta} /></NavLink>
                            <NavLink className="nav-link fw-700 t-plum ms-5" to="Careers"><img src={linkedin} /></NavLink>
                        </div>
                    </div>
                </div>
                <div className="mt-4" >
                    <p className="fs-14 t-ftr">iInvest is wholly owned by Next Capital Limited, a licensed brokerage firm of the Pakistan Stock Exchange operating out of Karachi, Pakistan. Next Capital Limited holds TREC license #048 and is regulated by the Securities and Exchange Comission of Pakistan. In addition, Next Capital Limited is a publicly listed entity on the Pakistan Stock Exchange, trading under the ticker, NEXT.
                        Disclosure: All investments involve risks, including the loss of principal. The content of this website do not constitute a recommendation to buy, sell, or hold any security or asset.
                    </p>
                    <p className="fs-12 t-ftr">Copyright © 2022. Next Capital Limited. All Rights Reserved</p>
                </div>
                <div className="d-flex">
                    <img className="me-4" src={JamaPunji} alt="" />
                    <img src={SECP} alt="" />
                </div>
            </div>
        </>
    );
}

export default Footer;
import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import $ from "jquery";
import AVN from '../../../Assets/Images/AVN.svg'
import SGF from '../../../Assets/Images/SGF.svg'
import MODAM from '../../../Assets/Images/MODAM.svg'
import IBFL from '../../../Assets/Images/IBFL.svg'
import TGL from '../../../Assets/Images/TGL.svg'
import EPCL from '../../../Assets/Images/EPCL.svg'
import EFERT from '../../../Assets/Images/EFERT.svg'
import GVGL from '../../../Assets/Images/GVGL.svg'
import MEBL from '../../../Assets/Images/MEBL.svg'
import SYS from '../../../Assets/Images/SYS.svg'
import TELE from '../../../Assets/Images/TELE.svg'
import TPLP from '../../../Assets/Images/TPLP.svg'
import IncreaseArrow from '../../../Assets/Images/IncreaseArrow.svg'
import crossRed from '../../../Assets/Images/crossRed.png'
import Markets from "../../../Assets/Data/Markets"
import timeLap from "../../../Assets/Data/timeLap"
import InvestmentRange from "../../../Assets/Data/InvestmentRange"
import Company from './Company';

class LowerSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'stocks',
            subType: 'TPLP',
            time: 0,
            amount: 25000,
            dataSet: Markets,
            timeMarks: timeLap,
            amountMarks: InvestmentRange,
            obtPerct: '0',
            AmountBecomed: 0,
            count: 0
        }
    }



    componentDidMount() {
        console.log('component', this.state)
        this.generateChart(this.state.type, this.state.subType)
        document.getElementById('TPLP').classList.add('CompanyActive')
    }

    addActiveMarket = (e) => {
        $(".stock-type > span").removeClass('Active')
        $(e.target).addClass('Active')
        this.setState({ type: e.target.dataset.val });

        this.generateChart(e.target.dataset.val, this.state.subType)
    }

    addActiveComp = (e) => {
        // alert('2')
        let Activecompanies = document.getElementsByClassName('CompanyActive')
        for (const item of Activecompanies) {

            item.classList.remove("CompanyActive")
        }

        e.target.parentNode.parentNode.classList.add('CompanyActive')
        this.setState({
            subType: e.target.parentNode.dataset.val
        }, () => { this.generateChart(this.state.type, this.state.subType) })


    }

    generateChart = (type, subtype) => {
        let temp = this.state.dataSet[0][type].length > 0 ? this.state.dataSet[0][type][0][subtype] : null  // Stock Prices of that selected
        // console.log({temp})
        if (temp) {
            // console.log('9', this.state.timeMarks[this.state.time].label )
            let PA = this.state.amount  //amount selected by user
            let timeTemp = this.state.timeMarks[this.state.time].label        // Which time is selected i-e '6M'
            // let oldPrice = temp[timeTemp]               // Sort out the Price for selected Period
            // let latestPrice = temp['latest']            // Latest Price of that stock
            // console.log(PA, oldPrice, latestPrice)
            // let present = PA * latestPrice / oldPrice       olddddd
            // let Present = ((PA / oldPrice) * latestPrice).toFixed(2)    //How much your investment has become now
            // let finalResult = ((Present - PA) * 100 / PA)    //Percentage
            let finalResult = temp[timeTemp]    //Percentage
            let Present = ((finalResult / 100) * PA) + PA    //How much your investment has become now
            // console.log(PA, finalResult, Present)
            // Present = +Present.toFixed(2)
            this.setState({
                AmountBecomed: Present.toFixed(0),
                obtPerct: finalResult.toFixed(2)
            })
            //  //  old Version
            // setPresentAmount(Present.toLocaleString())
            // setAmoutPercent(finalResult.toFixed(2))
            // // (PA - Present) * 100 / PA
        } else {
            this.setState({
                AmountBecomed: 0,
                obtPerct: '0'
            })
        }
    }

    numFormatter(num) {
        // alert(num)
        // return this.state.amountMarks[num].label
    }

    timeFormatter(num) {
        // return this.state.timeMarks[num].label
    }

    valuetext(value) {
        return `${value}RS`;
    }


    handleAmountChange = (newValue) => {
        // Investment Change
        console.log({ newValue })
        this.setState({
            amount: newValue.target.value * 5000
        }, () => { this.generateChart(this.state.type, this.state.subType) })

    };


    handleTimeChange = (newValue) => {
        console.log({ newValue })
        // Time change
        this.setState({
            time: newValue
        }, () => { this.generateChart(this.state.type, this.state.subType) })

    };

    calculateValue = (value) => {
        return value * 5000;
    }

    valueLabelFormat = (value) => {
        return `${value}`;
    }

    render() {
        return (
            <>
                {console.log(this.state)}
                <div className="container">
                    <div className="d-flex flex-row align-items-center justify-content-start overflow-auto mx-auto maxWidth pb-4">
                        <Company logo={TPLP} val='TPLP' addActiveComp={this.addActiveComp} />
                        <Company logo={SGF} val='SGF' addActiveComp={this.addActiveComp} />
                        <Company logo={IBFL} val='IBFL' addActiveComp={this.addActiveComp} />
                        <Company logo={TGL} val='TGL' addActiveComp={this.addActiveComp} />
                        <Company logo={MODAM} val='MODAM' addActiveComp={this.addActiveComp} />
                        <Company logo={EFERT} val='EFERT' addActiveComp={this.addActiveComp} />
                        <Company logo={GVGL} val='GVGL' addActiveComp={this.addActiveComp} />
                        <Company logo={MEBL} val='MEBL' addActiveComp={this.addActiveComp} />
                        <Company logo={AVN} val='AVN' addActiveComp={this.addActiveComp} />
                        <Company logo={EPCL} val='EPCL' addActiveComp={this.addActiveComp} />
                        <Company logo={SYS} val='SYS' addActiveComp={this.addActiveComp} />
                        <Company logo={TELE} val='TELE' addActiveComp={this.addActiveComp} />
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-8">
                            <div>

                                <div className="row">
                                    <button type="button" class={`col yearBtn ${this.state.time === 0 && 'Active'}`} onClick={() => this.handleTimeChange(0)} >1 year</button>
                                    <button type="button" class={`col yearBtn ${this.state.time === 1 && 'Active'}`} onClick={() => this.handleTimeChange(1)} >2 year</button>
                                    <button type="button" class={`col yearBtn ${this.state.time === 2 && 'Active'}`} onClick={() => this.handleTimeChange(2)} >3 year</button>
                                    <button type="button" class={`col yearBtn ${this.state.time === 3 && 'Active'}`} onClick={() => this.handleTimeChange(3)} >4 year</button>
                                    <button type="button" class={`col yearBtn ${this.state.time === 4 && 'Active'}`} onClick={() => this.handleTimeChange(4)} >5 year</button>
                                </div>
                            </div>
                            <div className="mt-5 px-3">
                                <div className="stock-amount-slider">
                                    <div className="d-flex amountSlider">
                                        <p className="mb-0 t-plum fs-18" >Investment Amount</p>
                                        <p className="mb-0 ms-auto t-blue fs-25 fw-600">{this.state.amount.toLocaleString()}PKR</p>
                                    </div>
                                    <Slider
                                        value={this.state.amount / 5000}
                                        min={2}
                                        step={2}
                                        max={50}
                                        scale={this.calculateValue}
                                        getAriaValueText={this.valueLabelFormat}
                                        valueLabelFormat={this.valueLabelFormat}
                                        onChange={this.handleAmountChange}
                                        aria-labelledby="non-linear-slider"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="AmountNpercent h-100 align-items-center justify-content-center d-flex p-3 grad-bodr">
                                <div className="" >
                                    <p className="t-plum fs-20">You would have</p>
                                    <p className="fs-35 fw-600" >PKR {parseInt(this.state.AmountBecomed).toLocaleString()}</p>
                                    {this.state.obtPerct.includes("-") ?
                                        <div className="d-flex align-items-center">
                                            <img src={crossRed} className='h20' alt='' /> <p className="fs-25 fw-600 t-red mb-0 ms-2"> {this.state.obtPerct}%</p>
                                        </div> :
                                        <div className="d-flex align-items-center">
                                            <img src={IncreaseArrow} className='h20' alt='' /> <p className="fs-25 fw-600 t-green mb-0 ms-2"> {this.state.obtPerct}%</p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LowerSection;

// fixed-top


import React from "react";
import { NavLink } from 'react-router-dom';
import Logo from '../../../Assets/Images/Logo.png'
import './Navbar.css'

const Navbar = () => {
  return (
    <>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3 close js-menu-toggle">
            <span aria-hidden="true" className="text-black">&times;</span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>

      <header className="site-navbar">
        <div className="container-fluid py-2 px-5">
          <div className="d-flex align-items-center">
            <div className="site-logo">
              <NavLink className="navbar-brand" to="/">
                <img src={Logo} alt="" className="logo" />
              </NavLink>
            </div>

            <nav className="mx-auto site-navigation">
              <ul className="site-menu js-clone-nav d-none d-xl-block ml-0 pl-0">

                <li>
                  <NavLink className="navbar-item t-purple" to="/">
                    Home
                  </NavLink>
                </li>

                <li>
                  <NavLink className="navbar-item t-purple" to="/About_US">
                    About Us
                  </NavLink>
                </li>

                <li>
                  <NavLink className="navbar-item t-purple" to="/Careers">
                    Careers
                  </NavLink>
                </li>

                <li>
                  <NavLink className="navbar-item t-purple" to="/FAQs">
                    FAQs
                  </NavLink>
                </li>

                <li className="d-lg-none">
                  <NavLink className="navbar-item t-purple" to="/login">
                    LOG IN
                  </NavLink>
                </li>

                <li className="d-lg-none">
                  <NavLink className="navbar-item t-purple" to="/Sign_Up">
                    SIGN UP
                  </NavLink>
                </li>

              </ul>
            </nav>

            <div className="right-cta-menu text-right d-flex aligin-items-center">
              <div className="ml-auto">

                <NavLink className="navbar-item t-purple btn d-none d-lg-inline-block" to="/login">
                  LOG IN
                </NavLink>

                <NavLink className="navbar-item btn text-white d-none d-lg-inline-block btn-filled" to="/Sign_Up">
                  SIGN UP
                </NavLink>

              </div>
              <a href="#" className="js-menu-toggle d-inline-block d-xl-none mt-lg-2 ml-3 navbar-dark">
                <span className="navbar-toggler-icon"></span>
              </a>
            </div>

          </div>
        </div>
      </header>

    </>
  );
};

export default Navbar;

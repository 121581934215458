const Company = (props) => {
    return ( 
        <>
        <div className="" >
                            <div className="mx-3 Complogo" id={props.val}>
                                <div data-val={props.val} className="">
                                    <img src={props.logo} alt="" onClick={props.addActiveComp} width="50" height="50" />
                                </div>
                            </div>
                        </div>
        </>
     );
}
 
export default Company;
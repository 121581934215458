import React from 'react'
import classes from "./FirstSection.module.css"
import Navbar from "../../../../SubComponent/Navbar/Navbar1"
import Email from '../../../../../Assets/Images/Email.svg'
import { NavLink } from 'react-router-dom'


const FirstSection = () => {
    return (
        <>
            <div className={classes.first_section}>
                <Navbar />
                <div className={classes.inner}>
                    <div className={classes.ttl}>
                        <p className={classes.Headline_outline + ' ' + classes.Headline}>
                            Invest Karo. Mehngai Say Bacho.
                        </p>

                        <p className={classes.Headline_front + ' ' + classes.Headline}>
                            Invest Karo. Mehngai Say Bacho.
                        </p>
                    </div>

                    <p className={`${classes.subHead} text-center mx-auto t-plum`} >Introducing the first community-based investment platform in Pakistan. Easy to sign up and easy to use, with all the insights you need to grow your hard earned savings in a responsible manner.</p>
                    <div className='text-center position-absolute bottom-0 w-100'>
                        <p className={classes.btmP} >Finqalab is coming to IBA first. Please register for our closed beta before May 1, 2022 to participate.
                            <div className={classes.btnDiv}>
                                <NavLink to="/" className={classes.btn}>
                                    <img src={Email} />
                                    <p className='text-white text-center'>JOIN OUR BETA</p>
                                </NavLink>
                            </div></p>
                    </div>

                </div>
            </div>
        </>
    );
}

export default FirstSection;
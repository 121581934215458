import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./font/webfonts/all.css"
import "./JS/main"


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// "apexcharts": "^3.29.0",
// "react-google-charts": "^3.0.15",

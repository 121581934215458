import React from 'react'
import thrd from './thrd.module.css'
import img1 from '../../../../../Assets/Images/img1.jpg'
import img2 from '../../../../../Assets/Images/img2.jpg'
import img3 from '../../../../../Assets/Images/img3.jpg'
import img4 from '../../../../../Assets/Images/img4.jpg'
import img5 from '../../../../../Assets/Images/img5.jpg'
import img6 from '../../../../../Assets/Images/img6.jpg'
import img7 from '../../../../../Assets/Images/img7.jpg'
import img8 from '../../../../../Assets/Images/img8.jpg'
import img9 from '../../../../../Assets/Images/img9.jpg'
import img10 from '../../../../../Assets/Images/img10.jpg'
import img11 from '../../../../../Assets/Images/img11.jpg'
import img12 from '../../../../../Assets/Images/img12.jpg'


const ThirdSection = () => {
    return (
        <>
            <div className="container mt-60">
                <div className="thirdSection">
                    <div>
                        <p className={`${thrd.head} text-center`}>Aj invest karo takay kal jalebi kha sako</p>
                    </div>

                    <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item px-5 active"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img1} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img2} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img3} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img4} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img5} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img6} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img7} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img8} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img9} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img10} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img11} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                            <div class="carousel-item px-5"  data-bs-interval="10000">
                                <div className='h-100 mx-auto d-flex'>
                                    <img src={img12} alt="" className='h-100 mx-auto' />
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                    {/* <div className='mt-60'>
                        <div className={`row g-4`}>
                            <div className={`${thrd.tags} col-12 col-md-6 col-lg-4`}>
                                <div className='bg-white'>
                                    
                                </div>
                            </div>
                            <div className={`${thrd.tags} col-12 col-md-6 col-lg-4`}>
                                <div className='bg-white'></div>
                            </div>
                            <div className={`${thrd.tags} col-12 col-md-6 col-lg-4`}>
                                <div className='bg-white'>
                                    
                                </div>
                            </div>
                            <div className={`${thrd.tags} col-12 col-md-6 col-lg-4`}>
                                <div className='bg-white'>
                                    
                                </div>
                            </div>
                            <div className={`${thrd.tags} col-12 col-md-6 col-lg-4`}>
                                <div className='bg-white'>
                                    
                                </div>
                            </div>
                            <div className={`${thrd.tags} col-12 col-md-6 col-lg-4`}>
                                <div className='bg-white'>
                                    
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* <div className='w-100 position-relative'>
                <div className="bgshadow shadow8"></div>
            </div> */}
        </>
    );
}

export default ThirdSection;
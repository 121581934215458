const timeLap = [
    {
        value: 0,
        scaledValue: 3,
        label: '1Y',
    },
    {
        value: 1,
        scaledValue: 6,
        label: '2Y',
    },
    {
        value: 2,
        scaledValue: 9,
        label: '3Y',
    },
    {
        value: 3,
        scaledValue: '1Y',
        label: '4Y',
    },
    {
        value: 4,
        scaledValue: '3Y',
        label: '5Y',
    }
];

export default timeLap
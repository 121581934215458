import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const MobileDescription = (props) => {
    return (

        <div className={props.snd.Column}>
            <div>
                <p className={`${props.snd.heading} t-plum`} >{props.heading}</p>
                <p className={`${props.snd.para} t-blue`} >{props.text}</p>

                <div className="d-inline-flex">
                    <p className={`${props.active == 1 && 'active'} mdl-step text-center`}></p>
                    <p className={`${props.active == 2 && 'active'} mdl-step text-center`}></p>
                    <p className={`${props.active == 3 && 'active'} mdl-step text-center`}></p>
                    <p className={`${props.active == 4 && 'active'} mdl-step text-center`}></p>
                    <p className={`${props.active == 5 && 'active'} mdl-step text-center`}></p>
                </div>
            </div>
        </div>
    );
}

export default MobileDescription;
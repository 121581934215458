const InvestmentRange = [
    {
        value: 0,
        scaledValue: 5000,
        label: '5000',
    },
    {
        value: 1,
        scaledValue: 10000,
        label: '10,000',
    },
    {
        value: 2,
        scaledValue: 50000,
        label: '50,000',
    },
    {
        value: 3,
        scaledValue: 100000,
        label: '100,000',
    },
    {
        value: 4,
        scaledValue: 200000,
        label: '200,000',
    }, 
    {
        value: 5,
        scaledValue: 300000,
        label: '300,000',
    },
    {
        value: 6,
        scaledValue: 500000,
        label: '500,000',
    },
];

export default InvestmentRange;
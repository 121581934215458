
import classes from "./Sixth.module.css"
import Email from '../../../../../Assets/Images/Email.svg'
import { NavLink } from 'react-router-dom'

const SixthSection = () => {
    return (
        <>
            <div className='mt-60 container-fluid pe-0'>
                <div className="bg-purple position-relative">
                    <p className="t-dpurple fs-60 fw-600 uniText">Finqalab is coming to the universities of Pakistan, starting from IBA, Karachi.</p>
                    <p className="t-dpurple fs-20 fw-600 uniText uniBtmText">Please register for our closed beta before May 1, 2022 to participate</p>
                    <div className={classes.btnDiv}>
                        <NavLink to="/" className={classes.btn}>
                            <img src={Email} />
                            <p className='text-white text-center'>JOIN OUR BETA</p>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SixthSection;
import React from 'react';
import FirstSection from './UpperSection/FirstSection/FirstSection'
import FourthSection from './UpperSection/FourthSection/FourthSection';
import SecondSection from './UpperSection/SecondSection/SecondSection';
import ThirdSection from './UpperSection/ThirdSection/ThirdSection';
// import LowerSection from './LowerSection';
import Footer from '../../SubComponent/Footer/Footer';
import FifthSection from './UpperSection/FifthSection/FifthSection';
import SixthSection from './UpperSection/SixthSection/SixthSection';

const Home = () => {
    return (
        <>
            <div>
                <div className='w-100 overflowX-clip'>
                    <div>
                        <div className='position-relative'>
                            <div className='newShadow newShadow5'></div>
                            <div className='newShadow newShadow12'></div>
                            <div className='newShadow newShadow13'></div>
                            <div className='newShadow newShadow14'></div>
                            <div className='newShadow newShadow15'></div>
                            <div className='newShadow newShadow16'></div>
                            <div className='newShadow newShadow17'></div>
                        </div>

                    </div>
                </div>
                <div className='zindex9'>

                <FirstSection />
                <SecondSection />
                <ThirdSection />
                <FourthSection />
                <FifthSection />
                <SixthSection />
                <Footer />
                </div>
            </div>

        </>
    );
}

export default Home;
import { useEffect } from "react"
import fifth from './fifth.module.css'

const FifthSection = () => {

    useEffect(() => {
        accord()
    }, [])


    const accord = (e) => {
        const data = document.getElementsByClassName('accordion-header')
        for (const item of data) {

            console.log({ item })
            if (item.children[0].ariaExpanded === 'true') {
                console.log(item.parentElement.parentElement)
                item.parentElement.parentElement.classList.add('gradClr')
            }
            else {
                item.parentElement.parentElement.classList.remove('gradClr')
            }
        }
    }

    return (
        <>
            <div className="container-fluid mt-60">
                        <p className={`${fifth.head} text-center`}>Frequently Asked Questions</p>
                <div className="accordion mt-60" id="accordionExample">
                    <div className="accordion-item my-3" onClick={accord}>
                        <div className="grad">
                            <div className="bg-white overflow-hidden">

                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button fs-20 fw-600 t-plum" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body fw-500 fs-20 t-plum">
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item  my-3" onClick={accord}>
                        <div className="grad">
                            <div className="bg-white overflow-hidden">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button fs-20 fw-600 t-plum collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body fw-500 fs-20 t-plum">
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item  my-3" onClick={accord}>
                        <div className="grad">
                            <div className="bg-white overflow-hidden">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button fs-20 fw-600 t-plum collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry?
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body fw-500 fs-20 t-plum">
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FifthSection;
import React from 'react'
import MobileDescription from '../../../../SubComponent/MobileDescription';
import snd from './Scnd.module.css'
import Close_knit from '../../../../../Assets/Images/Close_knit.svg'
import Fast_Onboarding from '../../../../../Assets/Images/Fast_Onboarding.svg'
import Automated_Payments from '../../../../../Assets/Images/Automated_Payments.svg'
import Insights_Driven from '../../../../../Assets/Images/Insights_Driven.svg'
import Analytical_Tools from '../../../../../Assets/Images/Analytical_Tools.svg'
import { AnimationOnScroll } from 'react-animation-on-scroll';


let opacity1 = 100
let w = window.innerWidth

console.log({ w })


window.onscroll = function (e) {

    if (window.scrollY < (w * 0.60078125)) {
        document.getElementById('one').style.opacity = 1
        document.getElementById('two').style.opacity = 1 - 1

    }
    else if (window.scrollY > (w * 0.6015625) && window.scrollY < (w * 0.97578125)) {
        // if (document.getElementById('two').style.opacity < 1)
        document.getElementById('two').style.opacity = 1
        document.getElementById('one').style.opacity = 1 - 1
        document.getElementById('three').style.opacity = 1 - 1

    }
    else if (window.scrollY > (w * 0.9765625) && window.scrollY < (w * 1.35078125)) {
        // if (document.getElementById('three').style.opacity < 1)
        document.getElementById('three').style.opacity = 1
        document.getElementById('two').style.opacity = 1 - 1
        document.getElementById('four').style.opacity = 1 - 1

    }
    else if (window.scrollY > (w * 1.3515625) && window.scrollY < (w * 1.71875)) {
        // if (document.getElementById('four').style.opacity < 1)
        document.getElementById('four').style.opacity = 1
        document.getElementById('three').style.opacity = 1 - 1
        document.getElementById('five').style.opacity = 1 - 1

    }
    else if (window.scrollY > (w * 1.71953125) && window.scrollY < (w * 1.953125)) {
        // if (document.getElementById('five').style.opacity < 1)
        document.getElementById('five').style.opacity = 1
        document.getElementById('four').style.opacity = 1 - 1

    }
};

const SecondSection = () => {
    return (
        <div className={`${snd.container} mt-5`}>
            <div className='row w-100 position-relative'>
                <div className='col-6'>
                    <div className="">


                        <AnimationOnScroll animateIn="animate__fadeInLeftBig h-100"
                            animateOut="animate__bounceOutLeft">
                            <div className="" style={{ height: '152.2vw', marginTop: '8vw' }}>
                                <div className='Mobile' id='one' style={{ opacity: opacity1 }}>
                                    <img src={Close_knit} alt='hand' className={snd.img} />
                                </div>                                <div className='Mobile' id="two" style={{ opacity: 0 }}>
                                    <img src={Fast_Onboarding} alt='hand' className={snd.img} />
                                </div>
                                <div className='Mobile' id="three" style={{ opacity: 0 }}>
                                    <img src={Automated_Payments} alt='hand' className={snd.img} />
                                </div>
                                <div className='Mobile' id="four" style={{ opacity: 0 }}>
                                    <img src={Insights_Driven} alt='hand' className={snd.img} />
                                </div>
                                <div className='Mobile' id="five" style={{ opacity: 0 }}>
                                    <img src={Analytical_Tools} alt='hand' className={snd.img} />
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>
                <div className='col-6'>
                    <MobileDescription snd={snd} img={Close_knit} heading='Close-knit Community' text='Engage with a vibrant community of investors and gain new insights.' active={1} />

                    <MobileDescription snd={snd} img={Fast_Onboarding} heading='Fast Onboarding' text='Get digitally onboarded in a matter of minutes and start investing.' active={2} />
                    <MobileDescription snd={snd} img={Automated_Payments} heading='Automated Payments' text='Easy, fast and secure. Deposit funds and start trading almost instantly.' active={3} />
                    <MobileDescription snd={snd} img={Insights_Driven} heading='Insights Driven' text='Obtained detailed company statistics, real-time price data and equity research.' active={4} />
                    <MobileDescription snd={snd} img={Analytical_Tools} heading='Analytical Tools' text='Track and analyze your portfolio performance in real-time and generate useful insights to inform your future investment decisions.' active={5} />

                </div>
            </div>



        </div>
    );
}

export default SecondSection;
import React from 'react'
import LowerSection from '../../../../SubComponent/APCPart/LowerSection';
import frth from './FrthSection.module.css'

const FourthSection = () => {
    return (
        <>
            <div className=" mt-60">
                        <p className={`${frth.head} text-center`}>Khud invest kar kay dekho kya kamao gay</p>
            </div>
            <div className='container mt-60'>

                {/* <div className='w-100 position-relative'>
                    <div className="bgshadow shadow9"></div>
                </div> */}
                <div className="ForthSection">
                    <div className="mt-60">
                        <LowerSection />

                    </div>
                </div>
            </div>
        </>
    );
}

export default FourthSection;